import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, CircularProgress, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import routes from './routes';
import SideMenu from './components/SideMenu';
import createNewTheme from './functions/utils';

const colorPrimary = "30, 58, 138";
const colorSecondary = "255, 153, 0";
const colorTertiary = "171, 172, 186";

const App = () => {
  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <CssBaseline />
      <Router>
        <SideMenu colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />
        <Suspense fallback={
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </Box>
        }>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.element}
                exact={route.exact}
              />
            ))}
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;