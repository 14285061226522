import { useState, useEffect } from 'react';

export const ScrollProgress = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const updateScroll = () => {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.clientHeight;
      const scrollPosition = window.scrollY;
      const scrollPercent = (scrollPosition / (fullHeight - windowHeight)) * 100;
      setScroll(Math.min(scrollPercent, 100));
    };

    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      height: '4px',
      background: 'linear-gradient(90deg, #b36b00, #ff9900)',
      width: `${scroll}%`,
      zIndex: 1000,
      transition: 'width 0.2s ease-out'
    }} />
  );
};