import { Alert, AppBar, Avatar, Box, Button, Divider, Fade, FormControl, IconButton, InputAdornment, ListItemButton, ListItemIcon, Menu, MenuItem, Modal, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { AccountCircleOutlined, Circle, EmojiEventsOutlined, ExpandMoreOutlined, LoginOutlined, MenuOutlined, PhoneOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../imagens/logo.png';
import { stringAvatar } from '../functions/utils';
import Cookies from 'js-cookie';
import { ScrollProgress } from './ScrollProgress';
import { useLocation, useNavigate } from 'react-router-dom';

const SideMenu = ({ update, colorPrimary }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [currentPage, setCurrentPage] = useState("home");
    const [open, setOpen] = React.useState(false);
    const [isLogged, setIsLogged] = React.useState(false);
    const [nomeUsuario, setNomeUsuario] = React.useState("");
    const location = useLocation(); // Pega o caminho atual (ex: "/dashboard")

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPerfil = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleOpenPerfil = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePerfil = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const changePage = (page) => {
        setOpen(false)
        handleClosePerfil();
        navigate(page)
    }

    useEffect(() => {
        const isLogged = Cookies.get('isLogged');
        setIsLogged(isLogged);
        if (isLogged) {
            setNomeUsuario(Cookies.get("nmUsr"))
        }
    }, []);

    useEffect(() => {
        setCurrentPage(location.pathname)
    }, [location]);

    useEffect(() => {
        if (location.state?.timestamp) {
            const isLogged = Cookies.get('isLogged');
            setIsLogged(isLogged);
            if (isLogged) {
                setNomeUsuario(Cookies.get("nmUsr"))
            }
        }
    }, [location.state]);

    const handleLogout = () => {
        Cookies.remove('isLogged');
        Cookies.remove('emUsr');
        Cookies.remove('cpUsr');
        Cookies.remove('cUsr');
        Cookies.remove('iUsr');
        Cookies.remove('nmUsr');
        Cookies.remove('tpCt');
        Cookies.remove('pTn');
        Cookies.remove('pSt');
        Cookies.remove('pLj');
        Cookies.remove('dtNsc');
        setIsLogged(false);
        setOpen(false);
        navigate("perfil", { state: { timestamp: Date.now() } });
        handleClosePerfil();
    }

    return (
        <>
            <AppBar position="fixed" sx={{ background: `rgba(255,255,255,0.4)`, backdropFilter: 'blur(10px)', height: 85, boxShadow: `0 4px 32px rgba(100,100,100,0.1)` }}>
                <ScrollProgress />

                <Toolbar>

                    {isMobile ?
                        <>
                            <IconButton size="large" color="inherit" style={{ color: "#1E3A8A" }} onClick={() => setOpen(true)}>
                                <MenuOutlined style={{ fontSize: 42 }} />
                            </IconButton>
                            <img src={Logo} style={{ width: 90, marginLeft: "auto" }} />
                        </>
                        :
                        <>
                            <img src={Logo} style={{ width: 90, margin: 20 }} />
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ marginLeft: "auto", textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("/")} > Home </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/loja" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/loja" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/loja" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("loja")} > Loja </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/retiradas" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/retiradas" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/retiradas" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("retiradas")} > Retiradas </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/treinos" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/treinos" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/treinos" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("treinos")} > Treinos </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/repasses" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/repasses" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/repasses" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("repasses")} > Repasses </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/beneficios" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/beneficios" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/beneficios" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("beneficios")} > Cupons </Button>
                            <Button variant="text"
                                startIcon={<Circle style={{ fontSize: 8 }} sx={{ mr: '-2px', mt: '-2px', color: currentPage === "/contato" ? "#ff9900" : "transparent", transition: 'color 0.3s ease', '.MuiButton-root:hover &': { color: (theme) => theme.palette.grey[500], fontSize: 8 } }} />}
                                sx={{ textTransform: 'none', fontWeight: 500, fontSize: 16, color: currentPage === "/contato" ? "#ff9900" : "#1c252e", borderRadius: 11, '&:hover': { '& .MuiSvgIcon-root': { color: (theme) => currentPage === "/contato" ? "#ff9900" : theme.palette.grey[500], fontSize: 8 } } }} onClick={() => changePage("contato")} > Contato </Button>

                            <div style={{ width: 20 }} />
                            <div style={{ height: 30, background: "#1c252e", width: 2 }} />
                            <div style={{ width: 20 }} />
                            {isLogged && nomeUsuario ?
                                <>
                                    <Avatar {...stringAvatar(nomeUsuario)} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer" }} onClick={handleOpenPerfil} />
                                    <Menu id="perfil" anchorEl={anchorEl} open={openPerfil} onClose={handleClosePerfil}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <div style={{ height: 10 }} />
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Avatar {...stringAvatar(nomeUsuario)} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16 }} />
                                            <div style={{ width: 10 }} />
                                            <Typography style={{ fontSize: 14, color: "#999" }}>
                                                <label style={{ fontSize: 16, color: "#1B1B1B" }}>{nomeUsuario}</label><br />
                                                {Cookies.get("emEst")}
                                            </Typography>
                                        </div>

                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        <List style={{ width: 315, fontSize: 16 }}>
                                            <ListItem button onClick={() => changePage("perfil")} style={{ cursor: "pointer" }}>
                                                <AccountCircleOutlined style={{ marginRight: 10, marginLeft: 4, color: "#464545" }} />
                                                <ListItemText primary="Ver perfil" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                            </ListItem>
                                            <ListItem button onClick={() => changePage("conquistas")} style={{ cursor: "pointer" }}>
                                                <EmojiEventsOutlined style={{ marginRight: 10, marginLeft: 4, color: "#464545" }} />
                                                <ListItemText primary="Minhas conquistas" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                            </ListItem>
                                            <ListItem button onClick={handleLogout} style={{ cursor: "pointer" }}>
                                                <LoginOutlined style={{ marginRight: 14, color: "#464545" }} />
                                                <ListItemText primary="Sair" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                            </ListItem>
                                        </List>
                                    </Menu>
                                </>
                                :
                                <Avatar style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer" }} onClick={() => changePage("perfil")} />
                            }

                        </>
                    }
                </Toolbar>
            </AppBar >

            {isMobile &&
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "400" }}>
                        <img src={Logo} style={{ width: 100, marginLeft: 20 }} />
                    </div>
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "/" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("/")}>
                            <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "/" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/loja" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("loja")}>
                            <ListItemText primary="Loja" primaryTypographyProps={{ color: currentPage === "/loja" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/retiradas" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("retiradas")}>
                            <ListItemText primary="Retiradas" primaryTypographyProps={{ color: currentPage === "/retiradas" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/treinos" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("treinos")}>
                            <ListItemText primary="Treinos" primaryTypographyProps={{ color: currentPage === "/treinos" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/repasses" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("repasses")}>
                            <ListItemText primary="Repasses" primaryTypographyProps={{ color: currentPage === "/repasses" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/beneficios" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("beneficios")}>
                            <ListItemText primary="Cupons" primaryTypographyProps={{ color: currentPage === "/beneficios" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "/contato" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("contato")}>
                            <ListItemText primary="Contato" primaryTypographyProps={{ color: currentPage === "/contato" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        {isLogged &&
                            <>
                                <div style={{ height: 20 }} />
                                <Divider />
                                <div style={{ height: 20 }} />
                            </>
                        }
                        <ListItem button style={{ background: currentPage === "perfil" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("perfil")}>
                            <ListItemText primary={isLogged ? "Ver perfil" : "Login"} primaryTypographyProps={{ color: currentPage === "perfil" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        {isLogged &&
                            <ListItem button style={{ background: currentPage === "conquistas" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("conquistas")}>
                                <ListItemText primary="Minhas conquistas" primaryTypographyProps={{ color: currentPage === "conquistas" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                            </ListItem>
                        }
                        {isLogged &&
                            <ListItem button style={{ background: currentPage === "logout" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={handleLogout}>
                                <ListItemText primary="Sair" primaryTypographyProps={{ color: currentPage === "logout" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                            </ListItem>
                        }
                    </List>
                </Drawer>
            }


        </>
    );
};

export default SideMenu;
