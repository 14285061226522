import { lazy } from 'react';

const HomeWebsite = lazy(() => import('../pages/HomeWebsite'));
const RetiradasKit = lazy(() => import('../pages/RetiradasKit'));
const Repasses = lazy(() => import('../pages/Repasses'));
const Contato = lazy(() => import('../pages/Contato'));
const Perfil = lazy(() => import('../pages/Perfil'));
const Loja = lazy(() => import('../pages/Loja'));
const Beneficios = lazy(() => import('../pages/Beneficios'));
const Conquistas = lazy(() => import('../pages/Conquistas'));
const Treinos = lazy(() => import('../pages/Treinos'));
const DetalhesProduto = lazy(() => import('../pages/DetalhesProduto'));
const DetalhesTreino = lazy(() => import('../pages/DetalhesTreino'));
const DetalhesSorteio = lazy(() => import('../pages/DetalhesSorteio'));

const routes = [
  {
    path: '/',
    element: <HomeWebsite />,
    exact: true
  },
  {
    path: '/retiradas',
    element: <RetiradasKit />
  },
  {
    path: '/treinos',
    element: <Treinos />
  },
  {
    path: '/treinos/detalhes',
    element: <DetalhesTreino />
  },
  {
    path: '/loja',
    element: <Loja />
  },
  {
    path: '/loja/detalhes',
    element: <DetalhesProduto />
  },
  {
    path: '/repasses',
    element: <Repasses />
  },
  {
    path: '/contato',
    element: <Contato />
  },
  {
    path: '/perfil',
    element: <Perfil />
  },
  {
    path: '/beneficios',
    element: <Beneficios />
  },
  {
    path: '/beneficios/detalhes',
    element: <DetalhesSorteio />
  },
  {
    path: '/conquistas',
    element: <Conquistas />
  }
];

export default routes;